<template>
  <div id="boxinfo">
    <div class="container">
      <div class="row">
        <div class="col-12 boxinfo">
          <div class="col-12 boxinfo-div searchbox" style="padding: 15px 0 0 0">
            <ScannerBox heading="Box Nummer"/>
          </div>
          <div id="boxinformation" style="display: none">
            <div class="col-12 boxinfo-div">
              <div v-if="showBackButton" style="padding: 5px; margin-bottom: 20px; border-bottom: 1px solid lightgray;border-top: 0.5px solid lightgray;">
                <span class="heading">...</span>
                <span v-on:click="back()" style="float: right; margin-left: 10px;">
                  <font-awesome-icon icon="arrow-alt-circle-left" size="lg"/>
                </span>
              </div>
            </div>
            <div class="col-12 boxinfo-div">
              <table class="fixed">
                <tbody>
                <tr>
                  <td><span class="heading">{{ $t('Box Number') }}:</span></td>
                  <td class="text-center"><span class="status">{{ $t('Status') }}</span></td>
                </tr>
                <tr>
                  <td><a class="value">{{ boxinfo.box_nr }}</a></td>
                  <td class="text-center">
                    <span v-html="boxStateHTML"></span>
                    <span> {{ $t(boxinfo.state) }}</span>
                  </td>
                </tr>
                </tbody>
              </table>
            </div>
            <div class="col-12 boxinfo-div"><span class="heading">{{ $t('Item Number') }}:</span><br> <a
                class="value">{{ boxinfo.material_nr }}</a></div>
            <div class="col-12 boxinfo-div"><span class="heading">{{ $t('Customer material number') }}:</span><br> <a
                class="value">{{ boxinfo.customer_material_nr }}</a></div>
            <div class="col-12 boxinfo-div"><span class="heading">{{ $t('Customer Material name') }}:</span><br> <a
                class="value">{{ boxinfo.customer_material_text }}</a></div>
            <div class="col-12 boxinfo-div"><span class="heading">{{ $t('Number of boxes') }}:</span><br> <a
                class="value">{{ boxinfo.box_count }}</a>
            </div>
            <div class="col-12 boxinfo-div"><span class="heading">{{ $t('Capacity per box') }}:</span><br> <a
                class="value">{{ boxinfo.filling_quantity }}</a></div>
            <div class="col-12 boxinfo-div"><span class="heading">{{ $t('Box type') }}:</span><br> <a
                class="value">{{ boxinfo.box_type }}</a>
            </div>
            <div class="col-12 boxinfo-div"><span class="heading">{{ $t('Last scan date') }}:</span><br> <a
                class="value">{{ boxinfo.last_scan }}</a></div>
            <div class="col-12 boxinfo-div"><span class="heading">{{ $t('Last goods issue date') }}:</span><br> <a
                class="value">{{ boxinfo.last_shipping_registration_date }}</a></div>
            <div class="col-12 boxinfo-div"><span class="heading">{{ $t('Last delivery number') }}:</span><br> <a
                class="value">{{ boxinfo.delivery_number }}</a></div>
            <div class="col-12 boxinfo-div"><span class="heading">{{ $t('Drop-off point') }}:</span>
              <br>
              <a class="value">{{ boxinfo.delivery_point }}</a>
            </div>
            <div class="col-12 boxinfo-div"><span class="heading">{{ $t('Storage location') }}:</span><br> <a
                class="value">{{ boxinfo.storage_location }}</a>
            </div>
            <div class="col-12 boxinfo-div"><span class="heading">{{ $t('Alternative storage locations') }}:</span><br>
              <span id="showAltStorageLoc" class="href" v-on:click="showAltStorage(boxinfo)">{{ $t('show') }}</span>
              <div id="altStorage" v-if="altStorageLocations.length > 0">
                                <span style="display:flex" v-for="box in altStorageLocations" v-bind:key="box.box_id">
                                    <span v-if="box.delivery_point !== boxinfo.delivery_point" class="href"
                                          v-on:click="goToStorage(box.delivery_point, boxinfo.material_nr)">{{
                                        box.delivery_point
                                      }}</span>
                                </span>
              </div>
            </div>
            <div class="col-12 boxinfo-div"><span class="heading">{{ $t('Active') }}:</span><br> <a
                class="value">{{ boxinfo.box_deleted }}</a>
            </div>
          </div>
        </div>
      </div>
    </div>
    <v-dialog :adaptive="true" width="80%"/>
  </div>
</template>

<script>
import config from '../config.js'
import axios from 'axios'
import auth from '../mage2rest.js'
import {Trans} from '@/plugins/Translation'
import ScannerBox from './ScannerBox.vue'

export default {
  data() {
    return {
      boxinfo: [],
      transInputPlaceHolder: this.$t('enter or scan'),
      boxStateHTML: '',
      altStorageLocations: [],
      currentBoxNr: null,
      boxStatus: {
        FULL: ['#91c352'],
        EMPTY: ['#fdba15'],
        IN_DELIVERY: ['#0f9cdc'],
        DELAY: ['#c4314b']
      },
      showBackButton: false,
      backRouteParams: {
        path: [],
      },
    }
  },
  components: {ScannerBox},
  created() {
    this.$on('scannerbox-result', (result) => {
      this.sendEvent(result);
    });
  },
  mounted() {
    this.initLocale();
    document.querySelector('#scanit').focus();

    if (this.$route.params.hasOwnProperty('path')) {

      this.backRouteParams.path = JSON.parse(this.$route.params.path);
      if(this.backRouteParams.path.length){
        this.showBackButton = true
      }

      this.sendEvent(this.$route.params.number);
    }
  },
  methods: {
    async showAltStorage(box) {

      let loader = this.$loading.show({
        loader: 'bars'
      });

      let companyId = JSON.parse(localStorage.getItem('proline_company'))[0].company_id;
      let authHeader = await auth.getAuthHeader();
      axios({
        method: 'get',
        url: config.routes.voss.boxInfo + '?' +
            'searchCriteria[filterGroups][0][filters][0][field]=material_nr' +
            '&searchCriteria[filterGroups][0][filters][0][value]=' + box.material_nr +
            '&searchCriteria[filterGroups][1][filters][1][field]=company_id' +
            '&searchCriteria[filterGroups][1][filters][1][value]=' + companyId,
        headers: authHeader
      }).then(response => {

        if (response.status === 200) {

          let duplicates = [];
          response.data.items.forEach((e) => {
            if (!duplicates.includes(e.delivery_point)) {
              this.altStorageLocations.push({location: e.storage_location, delivery_point: e.delivery_point})
              duplicates.push(e.delivery_point)
            }
          });

          document.querySelector('#showAltStorageLoc').style.display = 'none';
        }

      }).catch(e => {
        // eslint-disable-next-line no-console
        console.log(e)
      }).finally(() => {
        loader.hide();
      })
    },
    initLocale() {
      Trans.changeLanguage(localStorage.getItem('locale'));
    },
    async sendEvent(boxNr) {

      if (boxNr) {
        this.altStorageLocations = [];
        this.currentBoxNr = boxNr;

        let loader = this.$loading.show({
          loader: 'bars'
        });

        let authHeader = await auth.getAuthHeader();
        axios({
          method: 'get',
          url: config.routes.voss.boxInfo + '/' + boxNr,
          headers: authHeader
        }).then(response => {
          if (response.status === 200) {
            response.data.box_deleted = (response.data.box_deleted === true) ? this.$t('No') : this.$t('Yes');
            this.boxinfo = response.data;
            document.querySelector('#boxinformation').style.display = 'block';
            document.querySelector('.searchbox').style.borderBottom = '0';
            document.querySelector('#scanit').value = '';
            document.querySelector('#scanit').blur();

            if (this.boxinfo.state !== "") {
              this.boxStateHTML = '<span class="statusDot" style="background-color: ' + this.boxStatus[this.boxinfo.state] + '"></span>';
            } else {
              this.boxinfo.state = 'NEW';
              this.boxStateHTML = '<span class="statusDot" style="background-color: black"></span>';
            }
            this.boxinfo.box_deleted = (this.boxinfo.box_deleted === true) ? this.$t('No') : this.$t('Yes');
          }
        }).catch(e => {
          if (e.response.status === 404) {
            document.querySelector('#boxinformation').style.display = 'none';
            document.querySelector('.searchbox').style.borderBottom = '1px solid #e5e5e5';
            document.querySelector('#scanit').value = '';
            this.showModal('', "Die Box  Nummer " + boxNr + " wurde nicht gefunden.", 'zurück', null, (answer) => {
              if (answer) {
                this.hideModal()
              }
            });
          }
        }).finally(() => {
          loader.hide();
        })
      }
    },
    showModal(title, msg, button1, button2, callback) {
      let buttons = [];
      if (button1 !== null) {
        buttons.push({
          title: button1,
          handler: () => {
            return callback(true);
          }
        })
      }
      if (button2 !== null) {
        buttons.push({
          title: button2,
          handler: () => {
            return callback(false);
          }
        })
      }

      this.$modal.show('dialog', {
        text: "<div class='scan-modal emptybox-success'>" +
            "<div>" + title + "</div>" +
            "<div>" + msg + "</div>" +
            "</div>",
        buttons: buttons
      });
    },
    hideModal() {
      this.$modal.hide('dialog');
    },
    goToStorage(deliveryPoint, materialNum){
      this.backRouteParams.path.push(this.$route.name+':'+this.currentBoxNr)
      let number = deliveryPoint+'|'+materialNum
      this.$router.push({name: 'storageinfo', params: {number: number, materialNum, path: JSON.stringify(this.backRouteParams.path)}})
    },
    back() {
      if(this.backRouteParams.path){
        let path = this.backRouteParams.path;
        let pathSize = path.length;
        let arr = path[pathSize-1].split(":");
        this.$router.push(
            {name: arr[0],
              params: {number: arr[1], path: JSON.stringify(path.pop())}
            })
      }
    }
  }
}</script>

<style scoped>
.searchbox {
  border-bottom: 1px solid #e5e5e5;
  border-top: 1px solid #e5e5e5;
}

.fixed {
  table-layout: fixed;
  width: 100%;
  word-break: break-word;
}

.boxinfo {
  margin-top: 15px;
}

.boxinfo .boxinfo-div {
  text-align: left;
  padding-bottom: 10px;
  border-left: 1px solid #e5e5e5;
  border-right: 1px solid #e5e5e5;
}

.boxinfo .boxinfo-div:first-child {
  padding-bottom: 15px;
  padding-top: 15px;
}

.boxinfo .boxinfo-div:last-child {
  border-bottom: 1px solid #e5e5e5;
  margin-bottom: 10px;
}

.boxinfo .heading {
  font-weight: bold;
}

.boxinfo .status {
  text-align: right;
  font-weight: bold;
}

.boxinfo-div .dot {
  height: 25px;
  width: 25px;
  background-color: green;
  border-radius: 50%;
  display: inline-block;
}

input#scanit {
  margin-top: 5px;
}
</style>
