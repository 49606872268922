import { render, staticRenderFns } from "./SupportMenu.vue?vue&type=template&id=4efcf320&scoped=true&"
import script from "./SupportMenu.vue?vue&type=script&lang=js&"
export * from "./SupportMenu.vue?vue&type=script&lang=js&"
import style0 from "./SupportMenu.vue?vue&type=style&index=0&id=4efcf320&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../../../../usr/local/lib/node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4efcf320",
  null
  
)

export default component.exports