<template>
    <div id="voss-menu">
        <div class="container">
            <div class="menu col">
                <div class="row">
                    <div class="col-12">
                        <button v-on:click="$router.push('list')">
                            <font-awesome-icon icon="align-justify" size="lg"/>
                            {{$t('Box Scanning')}}
                        </button>
                    </div>
                </div>
                <div class="row">
                    <div class="col-12">
                        <button v-on:click="$router.push('infocenter')">
                            <font-awesome-icon icon="info-circle" size="lg"/>
                            {{$t('Info Center')}}
                        </button>
                    </div>
                </div>
                <div class="row">
                    <div class="col-12">
                        <button v-on:click="$router.push('supportmenu')">
                            <font-awesome-icon icon="phone" size="lg"/>
                            {{$t('Support Menu')}}
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>

</template>

<script>
    import { Trans } from '@/plugins/Translation'

    export default {
        name: 'vossMenu',
        mounted() {
            this.initLocale();
        },
        methods: {
            initLocale() {
                Trans.changeLanguage(localStorage.getItem('locale'));
            }
        }
    }
</script>