<template>
    <div>
        <div class="card-header" style="text-align: -webkit-center;">
            <font-awesome-icon icon="align-justify" style="color: #23538f"/>
            {{$t('Box Info')}}
        </div>
        <BoxInfo/>
    </div>
</template>
<script>
    import BoxInfo from '../components/BoxInfo'
    import { Trans } from '@/plugins/Translation'

    export default {
        components: {BoxInfo},
        name: 'boxinfo',
        data() {
            return {
                user: [],
            }
        },
        mounted() {
            Trans.changeLanguage(localStorage.getItem('locale'));
        }
    }
</script>