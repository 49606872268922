import { render, staticRenderFns } from "./StorageInfo.vue?vue&type=template&id=67f139b6&scoped=true&"
import script from "./StorageInfo.vue?vue&type=script&lang=js&"
export * from "./StorageInfo.vue?vue&type=script&lang=js&"
import style0 from "./StorageInfo.vue?vue&type=style&index=0&id=67f139b6&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../../../../usr/local/lib/node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "67f139b6",
  null
  
)

export default component.exports