<template>
  <div id="productinfo">
    <div class="container">
      <div class="row">
        <div class="col-12 productinfo">
          <div class="col-12 productinfo-div searchbox" style="padding: 15px 0 0 0">
            <ScannerBox heading="Item Number"/>
          </div>
          <div id="productinformation" style="display: none">
            <div class="col-12 productinfo-div"><span class="heading">{{ $t('Item Number') }}</span><br> <a
                class="value">{{ boxinfo.material_nr }}</a></div>
            <div class="col-12 productinfo-div"><span class="heading">{{ $t('Customer material number') }}:</span><br>
              <a
                  class="value">{{ boxinfo.customer_material_nr }}</a></div>
            <div class="col-12 productinfo-div"><span class="heading">{{ $t('Customer Material name') }}:</span><br>
              <a
                  class="value">{{ boxinfo.customer_material_text }}</a></div>
            <div class="col-12 productinfo-div"><span class="heading">{{ $t('Number of boxes') }}:</span><br> <a
                class="value">{{ inBoxes.length }}</a></div>
            <div class="col-12 productinfo-div"><span class="heading">{{ $t('Available in') }}:</span><br>
              <table class="fixed">
                <tbody>
                <tr v-for="box in inBoxes" v-bind:key="box.key">
                  <td>
                    <span class="href"
                          v-on:click="goToBox(box[0])">{{ box[0] }}</span>
                  </td>
                  <td>
                    <span v-html="box[1]"></span>
                    <span> ({{ $t(box[2]) }})</span>
                  </td>
                </tr>
                </tbody>
              </table>
            </div>
            <div class="col-12 productinfo-div"><span
                class="heading">{{ $t('Alternative storage locations') }}:</span><br>
              <span id="showAltStorageLoc" class="href" v-on:click="showAltStorage(boxinfo)">{{ $t('show') }}</span>
              <div id="altStorage" v-if="altStorageLocations.length > 0">
                                <span style="display:flex" v-for="box in altStorageLocations" v-bind:key="box.box_id">
                                    <span v-if="box.delivery_point !== boxinfo.delivery_point" class="href"
                                          v-on:click="goToStorage(box.delivery_point, boxinfo.material_nr)">{{ box.delivery_point }}</span>
                                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <v-dialog :adaptive="true" width="80%"/>
  </div>
</template>

<script>
import config from '../config.js'
import axios from 'axios'
import auth from '../mage2rest.js'
import ScannerBox from './ScannerBox'

export default {
  data() {
    return {
      boxinfo: [],
      inBoxes: [],
      inLocations: [],
      altStorageLocations: [],
      currentItemNr: null,
      boxStatus: {
        FULL: ['#91c352'],
        EMPTY: ['#fdba15'],
        IN_DELIVERY: ['#0f9cdc'],
        DELAY: ['#c4314b'],
        NEW: ['#000000']
      },
      transInputPlaceholder: this.$t('enter or scan')
    }
  },
  components: {ScannerBox},
  mounted() {
    document.querySelector('#scanit').focus()

    if (this.$route.params.hasOwnProperty('number')) {
      this.sendEvent(this.$route.params.number)
    }
  },
  created() {
    this.$on('scannerbox-result', (result) => {
      this.sendEvent(result);
    });
  },
  methods: {
    async showAltStorage(box) {

      let loader = this.$loading.show({
        loader: 'bars'
      });

      let companyId = JSON.parse(localStorage.getItem('proline_company'))[0].company_id;
      let authHeader = await auth.getAuthHeader();
      axios({
        method: 'get',
        url: config.routes.voss.boxInfo + '?' +
            'searchCriteria[filterGroups][0][filters][0][field]=material_nr' +
            '&searchCriteria[filterGroups][0][filters][0][value]=' + box.material_nr +
            '&searchCriteria[filterGroups][1][filters][1][field]=company_id' +
            '&searchCriteria[filterGroups][1][filters][1][value]=' + companyId,
        headers: authHeader
      }).then(response => {
        if (response.status === 200) {

          let duplicates = [];
          response.data.items.forEach((e) => {
            if (!duplicates.includes(e.delivery_point)) {
              this.altStorageLocations.push({location: e.storage_location, delivery_point: e.delivery_point})
              duplicates.push(e.delivery_point)
            }
          });

          document.querySelector('#showAltStorageLoc').style.display = 'none';
        }
      }).catch(e => {
        // eslint-disable-next-line no-console
        console.log(e)
      }).finally(() => {
        loader.hide();
      })
    },
    async sendEvent(itemNumber) {
      if (itemNumber) {

        this.altStorageLocations = [];

        let companyId = JSON.parse(localStorage.getItem('proline_company'))[0].company_id;

        if (companyId !== undefined) {

          let loader = this.$loading.show({
            loader: 'bars'
          });

          let authHeader = await auth.getAuthHeader();
          axios({
            method: 'get',
            url: config.routes.voss.boxInfo + '?' +
                'searchCriteria[filterGroups][0][filters][0][field]=material_nr' +
                '&searchCriteria[filterGroups][0][filters][0][value]=' + itemNumber +
                '&searchCriteria[filterGroups][1][filters][1][field]=company_id' +
                '&searchCriteria[filterGroups][1][filters][1][value]=' + companyId,
            headers: authHeader
          }).then(response => {
            if (response.status === 200) {

              this.currentItemNr = itemNumber;

              if (response.data.items[0] !== undefined) {
                this.boxinfo = response.data.items[0];
                this.boxinfo.box_deleted = (this.boxinfo.box_deleted === true) ? this.$t('No') : this.$t('Yes');
                document.querySelector('#productinformation').style.display = 'block';
                document.querySelector('.searchbox').style.borderBottom = '0';
                document.querySelector('#scanit').value = '';
                document.querySelector('#scanit').blur();

                let ctrl = this;
                response.data.items.forEach(function (e) {
                  if (e.state === '') {
                    e.state = 'NEW';
                  }
                  ctrl.inBoxes.push([e.box_nr, '<span class="statusDot" style="background-color: ' + ctrl.boxStatus[e.state] + '"></span>', e.state]);
                });
              } else {
                this.boxNotFound(itemNumber)
              }
            }
          }).catch(e => {
            if (e.status === 404) {
              this.boxNotFound(itemNumber)
            }
          }).finally(() => {
            loader.hide();
          })
        }

      }
    },
    boxNotFound(num) {
      document.querySelector('#productinformation').style.display = 'none';
      document.querySelector('.searchbox').style.borderBottom = '1px solid #e5e5e5';
      let msg = "Die Material Nummer '" + num + "' wurde nicht gefunden.";
      document.querySelector('#scanit').value = '';
      this.showModal('', msg, 'zurück', null, (answer) => {
        if (answer) {
          this.hideModal()
          document.querySelector('#scanit').focus();
        }
      });
    },
    showModal(title, msg, button1, button2, callback) {
      let buttons = [];
      if (button1 !== null) {
        buttons.push({
          title: button1,
          handler: () => {
            return callback(true);
          }
        })
      }
      if (button2 !== null) {
        buttons.push({
          title: button2,
          handler: () => {
            return callback(false);
          }
        })
      }

      this.$modal.show('dialog', {
        text: "<div class='scan-modal emptybox-success'>" +
            "<div>" + title + "</div>" +
            "<div>" + msg + "</div>" +
            "</div>",
        buttons: buttons
      });
    },
    hideModal() {
      this.$modal.hide('dialog');
    },
    goToStorage(deliveryPoint, materialNum){
      let path = [this.$route.name+':'+this.currentItemNr]
      let number = deliveryPoint+'|'+materialNum
      this.$router.push({name: 'storageinfo', params: {number: number, path: JSON.stringify(path)}})
    },
    goToBox(box){
      let path = [this.$route.name+':'+this.currentItemNr];
      this.$router.push({name: 'boxinfo', params: {number: box, path: JSON.stringify(path)}})
    }
  }
}</script>

<style scoped>

.searchbox {
  border-bottom: 1px solid #e5e5e5;
  border-top: 1px solid #e5e5e5;
}

.productinfo {
  margin-top: 15px;
}

.productinfo .productinfo-div {
  text-align: left;
  padding-bottom: 10px;
  border-left: 1px solid #e5e5e5;
  border-right: 1px solid #e5e5e5;
}

.productinfo .productinfo-div:first-child {
  padding-bottom: 15px;
  padding-top: 15px;
}

.productinfo .productinfo-div:last-child {
  border-bottom: 1px solid #e5e5e5;
  margin-bottom: 10px;
}

.productinfo .heading {
  font-weight: bold;
}

.productinfo .status {
  text-align: right;
  font-weight: bold;
}

input#scanit {
  margin-top: 5px;
}
</style>
