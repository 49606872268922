<template>
    <div>
        <div class="card-header" style="text-align: -webkit-center;">
            <font-awesome-icon icon="inbox"/>
            {{$t('Storage Location Info')}}
        </div>
        <StorageInfo/>
    </div>
</template>
<script>  import StorageInfo from '../components/StorageInfo'

export default {components: {StorageInfo}}</script>