<template>
  <div id="supportmenu">
    <div class="card-header" style="text-align: -webkit-center;">
      <font-awesome-icon icon="phone" size="lg" style="color: #23538f" rotation="90"/>
      {{$t('Support Menu')}}
    </div>
    <div class="container">
      <div class="menu col">
        <div class="row">
          <div class="col-12 company-contact">
            <span class="headline">VOSS {{$t('Contact Person')}}</span><br>
            <span>{{contact.firstname}} {{contact.lastname}}</span><br>
            <span>{{$t('Phone')}}: </span><a id="phone">{{contact.telephone}}</a><br>
            <span>{{$t('Fax')}}: </span><a>{{contact.fax}}</a><br>
            <span>{{$t('E-Mail')}}: </span><a id="mail">{{contact.email}}</a>
          </div>
        </div>
        <div class="row">
          <div class="col-12 contact">
            <span>VOSS Fluid GmbH</span><br>
            <span>Lüdenscheider Str. 52-54</span><br>
            <span>51688 Wipperfürth</span><br>
            <span>Deutschland</span>
          </div>
        </div>
      </div>
    </div>
  </div>

</template>

<script>
  import { Trans } from '@/plugins/Translation'

  export default {
    data() {
      return {
        contact: [],
      }
    },
    components: {

    },
    callContact(){
      document.location.href = 'tel:'+this.contact.telephone
    },
    emailContact(){
      location.href = "mailto:"+this.contact.email;
    },
    mounted() {
      Trans.changeLanguage(localStorage.getItem('locale'));
      this.contact = JSON.parse(localStorage.getItem('proline_company_contact'))[0];
      document.querySelector("a#phone").href = "tel:"+this.contact.telephone;
      document.querySelector("a#mail").href = "mailto:"+this.contact.email;
    }
  }

</script>

<style scoped>

  a{
    color: #23538f
  }
  .row {
    border-bottom: 1px solid #e5e5e5;
    padding: 15px 0 15px 0;
  }

  .div-head{
    text-align: left;
  }

  .head{
    font-size: 1.5rem;
  }

  .contact{
    text-align: left;
  }

  .company-contact{
    text-align: left;
  }

  .headline{
    font-weight: bold;
    text-decoration: underline;
  }
</style>