<template>
    <div>
        <div class="card-header" style="text-align: -webkit-center;">
            <font-awesome-icon icon="inbox" style="color: #23538f"/>
            {{$t('Product Info')}}
        </div>
        <ProductInfo/>
    </div>
</template>
<script>
    import ProductInfo from '../components/ProductInfo'
    import { Trans } from '@/plugins/Translation'

    export default {
        components: {ProductInfo},
        name: 'productinfo',
        mounted() {
            Trans.changeLanguage(localStorage.getItem('locale'));
        }
    }
</script>