<template>
  <div id="storageinfo">
    <div class="container">
      <div class="row">
        <div class="col-12 storageinfo">
          <div class="col-12 storageinfo-div searchbox" style="padding: 15px 0 0 0">
            <ScannerBox heading="Item Number" />
            <div style="padding: 0 15px 15px 15px">
              <a>{{ $t('Storage location filter') }}:</a>
              <select id="storageSelect">
                <option v-for="storage in storages" :key="storage.id">{{ storage.name }}</option>
              </select>
            </div>
          </div>
          <div style="text-align: center; text-align: -webkit-center" v-if="deliveryPointBoxes.TOTAL > 0">
            <div v-if="showBackButton" class="col-12 storageinfo-div">
              <div class="storageBoxes" style="border-bottom: 1px solid lightgray;border-top: 0.5px solid lightgray;">
                <span class="heading">...</span>
                <span v-on:click="back()" style="float: right; margin-left: 10px;">
                                    <font-awesome-icon icon="arrow-alt-circle-left" size="lg"/>
                                </span>
              </div>
            </div>
            <div id="percentResult">
              <div class="col-12 storagePercent" v-if="deliveryPointBoxes.FULL.length > 0">
                <a>{{ $t('Full') }}</a>
                <span v-on:click="showBoxes(deliveryPointBoxes.FULL)" class="boxStatusDot"
                      style="background-color: #91c352">
                                <a v-text="getStatusPercent(deliveryPointBoxes.FULL.length)"></a>
                                <a>%</a>
                            </span>
              </div>
              <div class="col-12 storagePercent" v-if="deliveryPointBoxes.EMPTY.length > 0">
                <a>{{ $t('Empty') }}</a>
                <span v-on:click="showBoxes(deliveryPointBoxes.EMPTY)" class="boxStatusDot"
                      style="background-color: #fdba15">
                                <a v-text="getStatusPercent(deliveryPointBoxes.EMPTY.length)"></a>
                                <a>%</a>
                            </span>
              </div>
              <div class="col-12 storagePercent" v-if="deliveryPointBoxes.IN_DELIVERY.length > 0">
                <a>{{ $t('In Delivery') }}</a>
                <span v-on:click="showBoxes(deliveryPointBoxes.IN_DELIVERY)" class="boxStatusDot"
                      style="background-color: #0f9cdc">
                                <a v-text="getStatusPercent(deliveryPointBoxes.IN_DELIVERY.length)"></a>
                                <a>%</a>
                            </span>
              </div>
              <div class="col-12 storagePercent" v-if="deliveryPointBoxes.DELAY.length > 0">
                <a>{{ $t('Delay') }}</a>
                <span v-on:click="showBoxes(deliveryPointBoxes.DELAY)" class="boxStatusDot"
                      style="background-color: #c4314b">
                                <a v-text="getStatusPercent(deliveryPointBoxes.DELAY.length)"></a>
                                <a>%</a>
                            </span>
              </div>
              <div class="col-12 storagePercent" v-if="deliveryPointBoxes.NEW.length > 0">
                <a>{{ $t('New') }}</a>
                <span v-on:click="showBoxes(deliveryPointBoxes.NEW)" class="boxStatusDot"
                      style="background-color: black">
                                <a v-text="getStatusPercent(deliveryPointBoxes.NEW.length)"></a>
                                <a>%</a>
                            </span>
              </div>
            </div>
          </div>
          <div id="storageBoxes" style="display: none">
            <div class="col-12 storageinfo-div">
              <div class="storageBoxes">
                <table class="fixed">
                  <tr>
                    <td class="heading">{{ $t('Boxes') }}:</td>
                    <td class="heading">{{ $t('Storage location') }}:</td>
                  </tr>
                  <tr v-for="box in selectedBoxes" v-bind:key="box.box_id">
                    <td><span class="href" v-on:click="showBoxInfo(box)">{{ box.box_nr }}</span></td>
                    <td>{{ box.storage_location }}</td>
                  </tr>
                </table>
              </div>
            </div>
          </div>

          <div id="boxinformation" style="display: none">
            <div class="col-12 storageinfo-div">
              <table class="fixed">
                <tbody>
                <tr>
                  <td><span class="heading">{{ $t('Box Number') }}:</span></td>
                  <td class="text-center"><span class="status">{{ $t('Status') }}</span></td>
                </tr>
                <tr>
                  <td><a class="value">{{ boxinfo.box_nr }}</a></td>
                  <td class="text-center">
                    <span v-html="boxStateHTML"></span>
                    <span> {{ $t(boxinfo.state) }}</span>
                  </td>
                </tr>
                </tbody>
              </table>
            </div>
            <div class="col-12 storageinfo-div"><span class="heading">{{ $t('Item Number') }}:</span><br> <a
                class="value">{{ boxinfo.material_nr }}</a></div>
            <div class="col-12 storageinfo-div"><span class="heading">{{ $t('Customer material number') }}:</span><br>
              <a
                  class="value">{{ boxinfo.customer_material_nr }}</a></div>
            <div class="col-12 storageinfo-div"><span class="heading">{{ $t('Customer Material name') }}:</span><br> <a
                class="value">{{ boxinfo.customer_material_text }}</a></div>
            <div class="col-12 storageinfo-div"><span class="heading">{{ $t('Number of boxes') }}:</span><br> <a
                class="value">{{ boxinfo.box_count }}</a>
            </div>
            <div class="col-12 storageinfo-div"><span class="heading">{{ $t('Capacity per box') }}:</span><br> <a
                class="value">{{ boxinfo.filling_quantity }}</a></div>
            <div class="col-12 storageinfo-div"><span class="heading">{{ $t('Box type') }}:</span><br> <a class="value">{{ boxinfo.box_type }}</a>
            </div>
            <div class="col-12 storageinfo-div"><span class="heading">{{ $t('Last scan date') }}:</span><br> <a
                class="value">{{ boxinfo.last_scan }}</a></div>
            <div class="col-12 storageinfo-div"><span class="heading">{{ $t('Last goods issue date') }}:</span><br> <a
                class="value">{{ boxinfo.last_shipping_registration_date }}</a></div>
            <div class="col-12 storageinfo-div"><span class="heading">{{ $t('Last delivery number') }}:</span><br> <a
                class="value">{{ boxinfo.delivery_number }}</a></div>
            <div class="col-12 storageinfo-div"><span class="heading">{{ $t('Drop-off point') }}:</span>
              <br>
              <a class="value">{{ boxinfo.delivery_point }}</a>
            </div>
            <div class="col-12 storageinfo-div"><span class="heading">{{ $t('Storage location') }}:</span><br> <a
                class="value">{{ boxinfo.storage_location }}</a>
            </div>
            <div class="col-12 storageinfo-div"><span class="heading">{{ $t('Active') }}:</span><br> <a
                class="value">{{ boxinfo.box_deleted }}</a>
            </div>
          </div>
        </div>
      </div>
    </div>
    <v-dialog :adaptive="true" width="80%"/>
  </div>
</template>

<script>
import config from '../config.js'
import axios from 'axios'
import auth from '../mage2rest.js'
import ScannerBox from './ScannerBox.vue'

export default {
  data() {
    return {
      storages: [{id: 0, name: this.$t('-- enter / scan article number --')}],
      storageinfo: [],
      selectedLocation: '',
      selectedBoxes: [],
      boxinfo: [],
      boxStateHTML: '',
      transInputPlaceHolder: this.$t('enter or scan'),
      storageId: null,
      materialNum: null,
      backRouteParams: {
        path: null,
      },
      showBackButton: false,
      deliveryPointBoxes: {
        TOTAL: [],
        FULL: [],
        EMPTY: [],
        IN_DELIVERY: [],
        DELAY: [],
        NEW: [],
      },
      boxStatus: {
        FULL: ['#91c352'],
        EMPTY: ['#fdba15'],
        IN_DELIVERY: ['#0f9cdc'],
        DELAY: ['#c4314b'],
        NEW: ['#000000']
      },
    }
  },
  components: {ScannerBox},
  mounted() {

    this.initEventListeners();

    if (this.$route.params.hasOwnProperty('number')) {
      this.backRouteParams.path = JSON.parse(this.$route.params.path);
      this.showBackButton = true

      let params = this.$route.params.number.split('|');
      let storage = params[0]
      let materialNum = params[1]

      this.initStorages(materialNum, storage);
    }
  },
  created() {
    this.$on('scannerbox-result', (result) => {
      this.initStorages(result);
    });
  },
  watch: {
    materialNum:  function (e) {
      let el = document.querySelector('#scanit');
      if(el){
        el.value = e
      }
      return (el && window.getComputedStyle(el, null).display !== 'none');
    }
  },
  methods: {
    showBoxInfo(box) {
      this.boxinfo = box;

      if (this.boxinfo.state !== "") {
        this.boxStateHTML = '<span class="statusDot" style="background-color: ' + this.boxStatus[this.boxinfo.state] + '"></span>';
      } else {
        this.boxinfo.state = 'NEW';
        this.boxStateHTML = '<span class="statusDot" style="background-color: black"></span>';
      }

      this.boxinfo.box_deleted = (this.boxinfo.box_deleted === true) ? this.$t('No') : this.$t('Yes');

      document.querySelector('#storageBoxes').style.display = 'none';
      document.querySelector('#boxinformation').style.display = 'block';

      this.showBackButton = true
    },
    goToBoxesPercent() {

      document.querySelector('#percentResult').style.display = 'block';
      document.querySelector('#storageBoxes').style.display = 'none';

      if (!this.isBackRoute()) {
        this.showBackButton = false
      }
    },
    hideAllViews() {
      document.querySelector('#percentResult').style.display = 'none';
      document.querySelector('.searchbox').style.borderBottom = '1px solid #e5e5e5';
    },
    goToBoxes() {
      document.querySelector('#boxinformation').style.display = 'none';
      document.querySelector('#storageBoxes').style.display = 'block';
      this.showBackButton = true
    },
    isBoxView() {
      let el = document.querySelector('#storageBoxes');
      return (el && window.getComputedStyle(el, null).display !== 'none');
    },
    isPercentView() {
      let el = document.querySelector('#percentResult');
      return (el && window.getComputedStyle(el, null).display !== 'none');
    },
    isBoxInformationView() {
      let el = document.querySelector('#boxinformation');
      return (el && window.getComputedStyle(el, null).display !== 'none');
    },
    isBackRoute() {
      return !!(this.backRouteParams.path)
    },
    showBoxes(boxes) {
      if (boxes.length > 0) {
        this.selectedBoxes = boxes;
        document.querySelector('#percentResult').style.display = 'none';
        document.querySelector('#storageBoxes').style.display = 'block';
        this.showBackButton = true
      }
    },
    getStatusPercent(amount) {
      return (100 / this.deliveryPointBoxes.TOTAL * amount).toFixed(2)
    },
    initEventListeners() {

      document.querySelector('#storageSelect').addEventListener('focus', () => {
        document.querySelector('body').scrollTop = 0;
      });

      let ctrl = this;
      document.querySelector('#storageSelect').addEventListener("change", function () {
        ctrl.getLocationInfo(this.value, ctrl.materialNum)
      });

    },
    async initStorages(materialNum = null, storage = null) {

      this.materialNum = materialNum;

      let companyId = JSON.parse(
          localStorage.getItem('proline_company')
      )[0].company_id

      if (typeof companyId !== undefined) {

        let loader = this.$loading.show({
          loader: 'bars'
        });

        let authHeader = await auth.getAuthHeader();
        let materialNumGet = (materialNum !== null) ? '&materialNum=' + materialNum : ''
        let companyGet = '/?companyId=' + companyId
        let getString = (materialNumGet) ? companyGet + materialNumGet : companyGet;
        axios({
          method: 'get',
          url: config.routes.voss.deliveryPoint + getString,
          headers: authHeader
        }).then(response => {
          if (response.status === 200) {

            let storages = [];
            for(let i = 0; i < response.data.length; i++) {
              if (response.data[i] !== '') {
                storages.push({id: i, name: response.data[i]});
              }
            }

            if(storages.length > 0){

              if(storage){
                for(let i = 0; i < storages.length; i++) {
                  if (storages[i].name === storage) {
                    this.getLocationInfo(storage, materialNum, true)
                    break
                  }
                }
              }

              loader.hide();
              this.storages = storages
              this.getLocationInfo(storages[0].name, materialNum, true)
            }else{
              this.materialNotFound(materialNum)
            }
          }
        }).catch((e) => {

          if (e.response.status === 400) {
            this.materialNotFound(materialNum)
          }

          if (e.response.status === 404) {
            this.materialNotFound(materialNum)
          }

        }).finally(() => {
          loader.hide();
        })
      }
    },
    materialNotFound(materialNum) {
      this.storages = [{id: 0, name: this.$t('-- Artikelnummer Eingeben/Scannen --')}]

      if(this.isBoxView()){
        document.querySelector('#storageBoxes').style.display = 'none';
      }

      if(this.isPercentView()) {
        document.querySelector('#percentResult').style.display = 'none';
      }

      if(this.isBoxInformationView()) {
        document.querySelector('#boxinformation').style.display = 'none';
      }

      document.querySelector('.searchbox').style.borderBottomStyle = 'solid';

      this.showBackButton = false

      this.showModal('', "Artikelnummer '%n' wurde nicht gefunden".replace('%n', materialNum), 'zurück', null,
          () => {
            this.hideModal()
          });
    },
    async getLocationInfo(location = null, materialNum = null, setSelection = false) {

      this.selectedLocation = location;

      let loader = this.$loading.show({
        loader: 'bars'
      });

      let companyId = JSON.parse(localStorage.getItem('proline_company'))[0].company_id;

      if (typeof companyId !== undefined) {

       let percentResult = document.querySelector('#percentResult');
       if (percentResult !== null) {
         percentResult.style.display = 'block';
       }

       let boxinformation = document.querySelector('#boxinformation');
       if (boxinformation !== null) {
         boxinformation.style.display = 'none';
       }

       let storageBoxes = document.querySelector('#storageBoxes');
       if (storageBoxes !== null) {
         storageBoxes.style.display = 'none';
       }

        let getString = '/?searchCriteria[filterGroups][0][filters][0][field]=company_id&searchCriteria[filterGroups][0][filters][0][value]=' + companyId

        if(location){
          getString += '&searchCriteria[filterGroups][1][filters][1][field]=delivery_point&searchCriteria[filterGroups][1][filters][1][value]=' + location
        }

        if(materialNum){
          getString += '&searchCriteria[filterGroups][2][filters][2][field]=material_nr&searchCriteria[filterGroups][2][filters][2][value]=' + materialNum
        }

        let ctrl = this;
        let authHeader = await auth.getAuthHeader();
        axios({
          method: 'get',
          url: config.routes.voss.boxInfo + getString,
          headers: authHeader
        }).then(response => {

          if (response.status === 200) {

            ctrl.deliveryPointBoxes.DELAY = []
            ctrl.deliveryPointBoxes.IN_DELIVERY = []
            ctrl.deliveryPointBoxes.EMPTY = []
            ctrl.deliveryPointBoxes.FULL = []
            ctrl.deliveryPointBoxes.NEW = []

            if (response.data.items.length > 0) {
              let sortBoxes = new Promise((resolve) => {
                ctrl.deliveryPointBoxes.TOTAL = response.data.items.length;

                response.data.items.forEach((value, index, array) => {
                  if (ctrl.deliveryPointBoxes[value.state] !== undefined) {
                    ctrl.deliveryPointBoxes[value.state].push(value);
                  }
                  if (value.state === '') {
                    ctrl.deliveryPointBoxes['NEW'].push(value);
                  }
                  if (index === array.length - 1) resolve();
                });
              });

              sortBoxes.then(() => {
                if (ctrl.deliveryPointBoxes.TOTAL > 0) {
                  document.querySelector('.searchbox').style.borderBottomStyle = 'unset';
                }
              })
            } else {
              document.querySelector('.searchbox').style.borderBottomStyle = 'solid';
            }

            if (setSelection) {
              let sel = document.getElementById('storageSelect')
              sel.value = location
            }

            if (!this.isBackRoute()) {
              this.showBackButton = false
            }
          }
        }).catch((e) => {
          if (e.response.status === 404) {
            document.querySelector('#storageinformation').style.display = 'none';
            document.querySelector('.searchbox').style.borderBottom = '1px solid #e5e5e5';
            let msg = ctrl.$t("There was no storage information for ") + document.querySelector('#storageSelect').value + "' gefunden.";
            this.showModal('', msg, 'zurück', null, function (answer) {
              if (answer) {
                ctrl.hideModal()
              }
            });
          }
        }).finally(() => {
          loader.hide();
        });
      }

      let percentResult = document.querySelector('#percentResult');
      if (percentResult !== null) {
        percentResult.style.display = 'block';
      }

      document.querySelector('#storageBoxes').style.display = 'none';
    },
    showModal(title, msg, button1, button2, callback) {
      let buttons = [];
      if (button1 !== null) {
        buttons.push({
          title: button1,
          handler: () => {
            return callback(true);
          }
        })
      }
      if (button2 !== null) {
        buttons.push({
          title: button2,
          handler: () => {
            return callback(false);
          }
        })
      }

      this.$modal.show('dialog', {
        text: "<div class='scan-modal emptybox-success'>" +
            "<div>" + title + "</div>" +
            "<div>" + msg + "</div>" +
            "</div>",
        buttons: buttons
      });
    },
    hideModal() {
      this.$modal.hide('dialog');
    },
    back() {
      if (this.isBoxView()) {
        this.goToBoxesPercent()
      } else if (this.isBoxInformationView()) {
        this.goToBoxes()
      } else if (this.isPercentView() && this.isBackRoute()) {
        let path = this.backRouteParams.path;
        let pathSize = path.length;
        let arr = path[pathSize - 1].split(":");
        path.pop()

        this.$router.push(
            {
              name: arr[0],
              params: {number: arr[1], path: JSON.stringify(path)}
            })

      } else {
        this.showBackButton = false
      }
    },
  }
}

</script>

<style scoped>

.storageBoxes {
  margin-top: 5px;
}

.searchbox {
  border-bottom: 1px solid #e5e5e5;
  border-top: 1px solid #e5e5e5;
}

.boxStatusDot {
  height: 85px;
  width: 85px;
  border-radius: 50%;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
}

.fixed {
  table-layout: fixed;
  width: 100%;
  word-break: break-word;
}

.storageinfo {
  margin-top: 15px;
}

.storageinfo .storagePercent {
  padding-bottom: 10px;
  border-left: 1px solid #e5e5e5;
  border-right: 1px solid #e5e5e5;
  display: inline-block;
}

.storageinfo .storagePercent:first-child {
  padding-bottom: 15px;
  padding-top: 15px;
}

.storageinfo .storagePercent:last-child {
  border-bottom: 1px solid #e5e5e5;
  margin-bottom: 10px;
  padding-bottom: 15px;
}

.storageinfo .storageinfo-div {
  text-align: left;
  padding-bottom: 10px;
  border-left: 1px solid #e5e5e5;
  border-right: 1px solid #e5e5e5;
}

.storageinfo .storageinfo-div:first-child {
  padding-bottom: 15px;
  padding-top: 15px;
}

.storageinfo .storageinfo-div:last-child {
  border-bottom: 1px solid #e5e5e5;
  margin-bottom: 10px;
}

.storageinfo .storageinfo-div div {
  padding-bottom: 5px;
  padding-top: 5px;
}

.storageinfo .heading {
  font-weight: bold;
}

.storageinfo .status {
  text-align: right;
  font-weight: bold;
}

.storageinfo-div .dot {
  height: 15px;
  width: 15px;
  border-radius: 50%;
  display: inline-block;
}

select#storageSelect {
  margin-top: 5px;
}
</style>
