<template>
    <div id="scanlist">
        <ScannerBox heading="Box Nummer"/>
        <hr style="margin: 0">
        <div class="container">
            <div class="row">
                <div class="col-7" style="text-align: left;padding-left: 25px; text-decoration: underline;"><span>{{ $t('Scanned Containers') }}</span>
                </div>
                <div class="col-5" style="margin-bottom: 10px; padding-right: 25px"><span>{{$t('Amount')}}: {{scanCount}}</span>
                </div>
                <div class="col-12 scantable">
                    <table class="fixed_header" style="border:1px solid #e5e5e5;">
                        <thead class="thead-light">
                        <tr>
                            <th style="text-align: center;width: 100%">Scanlist</th>
                            <th></th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr v-for="entry in itemList" :key="entry.box_nr">
                            <td>{{entry.box_nr}}</td>
                            <td style="text-align: center" v-on:click="removeElement(entry.box_nr)">
                                <font-awesome-icon icon="trash-alt"/>
                            </td>
                        </tr>
                        </tbody>
                    </table>
                </div>
            </div>
            <div class="row">
                <div class="col-12 sendbutton">
                    <button class="sendlist-button" v-on:click="sendEvent()">
                        <font-awesome-icon icon="paper-plane"/>
                        {{$t('Transmit Scans')}}
                    </button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import mage2rest from '../mage2rest.js'
    import {Trans} from '@/plugins/Translation'
    import {EventBus} from '../event-bus.js'
    import ScannerBox from './ScannerBox'

    export default {
        name: "Scanlist",
        components: {ScannerBox},
        data() {
            return {
                itemList: [],
                scanCount: 0,
                scanning: false,
            }
        },
        watch: {
            scanCount: function (e) {
                EventBus.$emit('scanCountChanged', e);
            }
        },
        mounted() {
            this.initLocale()
            this.initItemList()
        },
        created() {
            this.$on('scannerbox-result', (result) => {
                this.addBarcode(result)
            });
        },
        methods: {
            initLocale() {
                Trans.changeLanguage(localStorage.getItem('locale'));
            },
            initItemList() {
                if (localStorage.item_list) {
                    let items = JSON.parse(localStorage.getItem('item_list'));
                    for (var key in items) {
                        this.itemList.push(items[key]);
                    }
                    this.scanCount = Object.keys(this.itemList).length;
                }
            },
            addItem(boxnr) {
                let item = {box_nr: boxnr}
                this.itemList.unshift(item);
                this.scanCount = Object.keys(this.itemList).length;
                localStorage.setItem('item_list', JSON.stringify(this.itemList));
            },
            clearList() {
                this.itemList = [];
                localStorage.setItem('item_list', JSON.stringify(this.itemList));
            },
            removeItemByElement(element) {
                for (let i = this.itemList.length - 1; i >= 0; i--) {
                    if (this.itemList[i] == element) this.itemList.splice(i, 1);
                }
                localStorage.setItem('item_list', JSON.stringify(this.itemList));
                this.scanCount = Object.keys(this.itemList).length;
            },
            removeItem(position) {
                this.itemList.splice(position, 1);
                localStorage.setItem('item_list', JSON.stringify(this.itemList));
                this.scanCount = Object.keys(this.itemList).length;
            },
            sendEvent() {
                let ctrl = this;
                if (this.itemList.length > 0) {
                    this.showModal(this.$t('Transmit Data?'), '', this.$t('Yes'), this.$t('No'), function (answer) {
                        if (answer) {
                            ctrl.boxesEmptyScan()
                            ctrl.hideModal()
                        } else {
                            ctrl.hideModal()
                        }
                    });
                }
            },
            async boxesEmptyScan() {

                let loader = this.$loading.show({
                    loader: 'bars'
                });

                let ctrl = this;

                mage2rest.setEmptyScanBoxes(this.itemList)
                    .then((response) => {

                      if (response.status === 200) {
                        let itemSize = this.itemList.length;
                        let successMsg = "<span>" + itemSize + " / " + itemSize + " " + this.$t('Transfered Containers!') + "</span>";
                        this.showModal(this.$t("Upload Successful"), successMsg, this.$t('back'), null, function (answer) {
                          if (answer) {
                            ctrl.hideModal();
                          }
                        });
                        this.clearList();

                        return null
                      }

                      if({...response}.response.status === 422) {
                        if ({...response}.response.data.code === -1) {
                          let items = {...response}.response.data.parameters;
                          if (items !== null && items.length > 0) {

                            let responseBoxes = [];
                            items.forEach(function (e) {
                              responseBoxes.push(e.box_nr);
                            });

                            let error = 0;
                            let success = 0;
                            let boxesToRemove = [];
                            this.itemList.forEach((e) => {
                              if (responseBoxes.indexOf(e.box_nr) === -1) {
                                boxesToRemove.push(e)
                                success++
                              } else {
                                error++
                              }
                            });

                            boxesToRemove.forEach((e) => {
                              this.removeItemByElement(e);
                            });

                            let total = success + error;
                            let successMsg = (success > 0) ? "<span>" + success + " / " + total + " " + this.$t('Containers') + "<b> " + this.$t('transfered') + "</b> </span>" : '';
                            let errorMsg = (error > 0) ? "<span>" + error + " / " + total + " " + this.$t('Containers') + "<b> " + this.$t('faulty') + "</b> </span>" : '';
                            this.showModal(this.$t("Note"), successMsg.concat(errorMsg), this.$t('back'), null, function (answer) {
                              if (answer) {
                                ctrl.hideModal();
                              }
                            });
                          }
                        }
                      }else {
                        let errorMsg = "<span>Fehlercode: " + {...response}.response.status + "</span>" +
                            "<span>" + {...response}.response.statusText + "</span>";
                        this.showModal("Transmission not possible", errorMsg, this.$t('back'), null, (answer) => {
                          if (answer) {
                            ctrl.hideModal();
                          }
                        });
                      }
                }).finally(() => {
                  loader.hide();
                });
            },
            removeElement: function (itemNum) {
                let ctrl = this;
                for (let i = this.itemList.length - 1; i >= 0; i--) {
                    if (this.itemList[i].box_nr === itemNum) {
                        let msg = this.$t('Should the scan $s be removed?').replace('$s', this.itemList[i].box_nr);
                        this.showModal('', msg, this.$t('Yes'), this.$t('No'), function (answer) {
                            if (answer) {
                                ctrl.removeItem(i)
                                ctrl.hideModal()
                            } else {
                                ctrl.hideModal()
                            }
                        });
                    }
                }
            },
            itemExist(boxNum) {
                for (let i = this.itemList.length - 1; i >= 0; i--) {
                    if (this.itemList[i].box_nr === boxNum) {
                        return true;
                    }
                }
                return false;
            },
            addBarcode(code){
                if (!this.itemExist(code)) {
                    this.addItem(code)
                }
            },
            showModal(title, msg, button1, button2, callback) {
                let buttons = [];
                if (button1 !== null) {
                    buttons.push({
                        title: button1,
                        handler: () => {
                            return callback(true);
                        }
                    })
                }
                if (button2 !== null) {
                    buttons.push({
                        title: button2,
                        handler: () => {
                            return callback(false);
                        }
                    })
                }

                this.$modal.show('dialog', {
                    text: "<div class='scan-modal emptybox-success'>" +
                        "<div>" + title + "</div>" +
                        "<div>" + msg + "</div>" +
                        "</div>",
                    buttons: buttons
                });
            },
            hideModal() {
                this.$modal.hide('dialog');
            },
        }
    }
</script>

<style scoped>
    ::-webkit-scrollbar-track {
        -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
        background-color: #F5F5F5;
    }

    ::-webkit-scrollbar {
        width: 10px;
        background-color: #cccccc;
    }

    ::-webkit-scrollbar-thumb {
        background-color: #f8f8f8;
        border: 2px solid #cccccc;
    }

    .fixed_header {
        width: 100%;
        table-layout: fixed;
        border-collapse: collapse;
    }

    .fixed_header tbody {
        display: block;
        width: 100%;
        overflow: auto;
        height: 100px;
    }

    .fixed_header thead tr {
        display: block;
    }

    .fixed_header thead {
        background: #156a9d;
        color: #fff;
    }

    .fixed_header th, .fixed_header td {
        padding: 5px;
        text-align: left;
        width: 400px;
    }

    tbody tr td {
        border-bottom: 1px solid #cccccc;
    }

</style>
