<template>
  <div>
    <div class="container">
      <div class="home col">
        <div class="row" >
          <Scanlist/>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import Scanlist from '../components/Scanlist'

  export default {
    components: {
      Scanlist
    }
  }

</script>