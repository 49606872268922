<template>
    <div id="voss-menu">
        <div class="container">
            <div class="menu col">
                <div class="row">
                    <div class="col-12">
                        <button v-on:click="goToBoxInfo()">
                            {{$t('Box Info')}}</button>
                    </div>
                </div>
                <div class="row">
                    <div class="col-12">
                        <button v-on:click="goToProductInfo()">
                            {{$t('Item Info')}}</button>
                    </div>
                </div>
                <div class="row">
                    <div class="col-12">
                        <button v-on:click="goToStorageInfo()">
                            {{$t('Storage Info')}}</button>
                    </div>
                </div>
                <div class="row">
                    <div class="col-12">
                        <button v-on:click="goToDownloadCenter()">
                            {{$t('Downloadcenter')}}</button>
                    </div>
                </div>
            </div>
        </div>
    </div>

</template>

<script>
    import config from '../config.js'
    import { Trans } from '@/plugins/Translation'

    export default {
        name: 'infocenter',
        methods: {
            goToDownloadCenter(){
                window.open(config.links.store.downloadCenter);
            },
            goToBoxInfo(){
                this.$router.push('boxinfo');
            },
            goToStorageInfo(){
                this.$router.push('storageinfo');
            },
            goToProductInfo(){
                this.$router.push('productinfo');
            }
        },
        mounted() {
            Trans.changeLanguage(localStorage.getItem('locale'));
        }
    }
</script>