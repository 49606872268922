<template>
    <div>
        <div class="container">
            <div class="row scaninput" style="padding-bottom: 20px">
                <div class="col-9" style="padding-right: 0; text-align: left">
                    <span><label>{{$t(heading)}}:</label></span>
                    <input id="scanit"
                           style="border: 1px solid black; width: 100%; border-radius: 4px; padding: 4px 12px;"
                           v-bind:placeholder="transInput" v-model="scanValue">
                </div>
                <div class="col-3" style="display: flex">
                    <div style="align-self: flex-end">
                        <button id="barcodeCam" v-on:click="initScanDialog()"
                                style="background-image: none; background-color: white; border: none;">
                            <font-awesome-icon icon="barcode" color="black" size="2x"/>
                        </button>
                    </div>

                </div>
            </div>
        </div>
        <modal
                width="90%"
               height="95%"
               id="scanDialog"
               name="scanDialog"
        >
            <div class="container" style="background-color: #156a9d">
                <div style="display: flex; flex-direction: row-reverse;">
                    <span style="padding: 2px; color: white" @click="$modal.hide('scanDialog')">X</span>
                </div>
            </div>
            <div id="camCardSection" style="overflow: hidden">
                <div class="container" style="width: 100%; padding-top: 15px;">
                    <Scanner :reader-size="readerSize" :onDetected="barcodeDetected"/>
                </div>
            </div>
            <hr style="margin-top: 15px; margin-bottom: 8px">
            <div class="container">
                <div class="row" style="padding: 0px">
                    <div class="col-12">
                        <div class="row" style="padding-top: 5px; padding-bottom: 5px; padding-right: 0">
                            <div class="col-9" style="padding-right: 0; padding-left: 25px">
                                <div class="col-12 text-center" style="display: table; border: 1px dotted grey; height: 100%">
                                    <span style="display:table-cell; vertical-align:middle;">{{detectedCode}}</span>
                                </div>
                            </div>
                            <div class="col-3">
                                <button style="background-image: none; background-color: white; border: none; width: 40px; height: 40px; padding: 8px" v-on:click="returnResult(detectedCode)" :disabled="!detectedCode">
                                    <font-awesome-icon icon="plus" color="black" size="1x"/>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </modal>
    </div>
</template>

<script>
    import Scanner from './Scanner'

    export default {
        name: 'ScannerBox',
        components: {Scanner},
        data: function() {
            return {
                barcodes: [],
                detectedCode: '',
                readerSize: {
                    width: 640,
                    height: 480
                },
                scanValue: '',
                transInput: this.$t('enter or scan'),
            };
        },
        props: {
            heading: String
        },
        mounted() {
            this.initEventListeners()
        },
        methods: {
            initDefaults() {
                this.detectedCode = ''
            },
            initScanDialog(){

                let ctrl = this

                if (navigator.mediaDevices && typeof navigator.mediaDevices.getUserMedia === 'function') {
                    let iOS = !!navigator.platform && /iPad|iPhone|iPod/.test(navigator.platform);
                    if(iOS){
                        this.readerSize.width = 1280
                        this.readerSize.height = 720
                    }
                    ctrl.$modal.show('scanDialog')
                    ctrl.initDefaults()
                    ctrl.initStreamSize()
                }else {
                    this.showModal(ctrl.$t("Note"), ctrl.$t("Browser is not compatible"), ctrl.$t('cancel'), null, (answer) => {
                        if (answer) {
                            ctrl.hideModal();
                        }
                    });
                }
            },
            async initStreamSize(){

                while (!document.querySelector('video')) {
                    await new Promise((resolve) => setTimeout(resolve, 500))
                }

                while (document.querySelector('video').hasOwnProperty('duration')) {
                    await new Promise((resolve) => setTimeout(resolve, 500))
                }

                while (isNaN(document.querySelector('video').duration)) {
                    await new Promise((resolve) => setTimeout(resolve, 500))
                }

                let streamHeight = document.querySelector('video').clientHeight
                let modalHeight = document.querySelector('.v--modal-box.v--modal').offsetHeight-30-80

                if(modalHeight && streamHeight && (streamHeight > modalHeight)){
                    document.querySelector('#camCardSection').style.height = modalHeight + 'px'
                }
            },
            barcodeDetected(data) {
                let ctrl = this;

                if (data.codeResult.code !== undefined) {

                    if (isNaN(ctrl.barcodes[data.codeResult.code])) {
                        ctrl.barcodes[data.codeResult.code] = 0;
                    } else {
                        ctrl.barcodes[data.codeResult.code]++;
                    }

                    if (ctrl.barcodes[data.codeResult.code] >= 5) {
                        this.detectedCode = data.codeResult.code
                        ctrl.barcodes = [];
                    }
                }
            },
            initEventListeners() {
                document.querySelector('#scanit').addEventListener('focus', () => {
                    document.querySelector('.row.scaninput').scrollIntoView();
                });
                document.querySelector('#scanit').addEventListener('keypress', (e) => {
                    let key = e.which || e.keyCode;
                    if (key === 13) {
                        if (this.scanValue !== '') {
                          this.returnResult(this.scanValue);
                          this.scanValue = '';
                        }
                    }
                });
            },
            returnResult(code){
                this.$parent.$emit('scannerbox-result', code)
                this.detectedCode = ''
            },
            showModal(title, msg, button1, button2, callback) {
                let buttons = [];
                if (button1 !== null) {
                    buttons.push({
                        title: button1,
                        handler: () => {
                            return callback(true);
                        }
                    })
                }
                if (button2 !== null) {
                    buttons.push({
                        title: button2,
                        handler: () => {
                            return callback(false);
                        }
                    })
                }

                this.$modal.show('dialog', {
                    text: "<div class='scan-modal emptybox-success'>" +
                        "<div>" + title + "</div>" +
                        "<div>" + msg + "</div>" +
                        "</div>",
                    buttons: buttons
                });
            },
            hideModal() {
                this.$modal.hide('dialog');
            }
        },
    };
</script>

<style scoped>

</style>
