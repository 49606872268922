<template>
  <div id="interactive" class="viewport scanner">
    <video />
    <canvas class="drawingBuffer" />
  </div>
</template>

<script>
  import Quagga from 'quagga';

  export default {
    name: 'QuaggaScanner',
    props: {
      onDetected: {
        type: Function,
        default(result) {
          // eslint-disable-next-line no-console
          console.log('detected: ', result);
        },
      },
      onProcessed: {
        type: Function,
        default(result) {
          let drawingCtx = Quagga.canvas.ctx.overlay;

          let drawingCanvas = Quagga.canvas.dom.overlay;

          if (result) {
            if (result.boxes) {
              drawingCtx.clearRect(
                0,
                0,
                parseInt(drawingCanvas.getAttribute('width')),
                parseInt(drawingCanvas.getAttribute('height'))
              );
              result.boxes
                .filter(function(box) {
                  return box !== result.box;
                })
                .forEach(function(box) {
                  Quagga.ImageDebug.drawPath(box, { x: 0, y: 1 }, drawingCtx, {
                    color: 'black',
                    lineWidth: 2,
                  });
                });
            }
            if (result.box) {
              Quagga.ImageDebug.drawPath(result.box, { x: 0, y: 1 }, drawingCtx, {
                color: '#156A9E',
                lineWidth: 2,
              });
            }

            if (result.codeResult && result.codeResult.code) {
              Quagga.ImageDebug.drawPath(
                result.line,
                { x: 'x', y: 'y' },
                drawingCtx,
                { color: '#e4007b', lineWidth: 3 }
              );
            }
          }
        },
      },
      readerTypes: {
        type: Array,
        default: () => ['code_128_reader'],
      },
      readerSize: {
        type: Object,
        default: () => ({
          width: 640,
          height: 480,
        }),
        validator: o =>
          typeof o.width === 'number' && typeof o.height === 'number',
      },
      aspectRatio: {
        type: Object,
        default: () => ({
          min: 1,
          max: 2,
        }),
        validator: o => typeof o.min === 'number' && typeof o.max === 'number',
      },
    },
    data: function() {
      return {
        quaggaState: {
          inputStream: {
            type: 'LiveStream',
            constraints: {
              width: { min: this.readerSize.width },
              height: { min: this.readerSize.height },
              facingMode: 'environment',
              aspectRatio: { min: 1, max: 2 },
            },
          },
          locator: {
            patchSize: 'medium',
            halfSample: true,
          },
          numOfWorkers: 2,
          frequency: 10,
          decoder: {
            readers: this.readerTypes,
          },
          locate: true,
        },
      };
    },
    mounted: function() {
      let ctrl = this
      Quagga.init(this.quaggaState, function(err) {
        if (err) {
          if(err.name === "OverconstrainedError") {
            ctrl.quaggaState.inputStream.constraints.width = { min: 640 }
            ctrl.quaggaState.inputStream.constraints.height = { min: 480 }
            Quagga.init(ctrl.quaggaState, function(err) {
              if (err) {
                // eslint-disable-next-line no-console
                return console.error(err);
              }
            });
          }
        }else {
          Quagga.start();
        }
      });
      Quagga.onDetected(this.onDetected);
      Quagga.onProcessed(this.onProcessed);
    },
    destroyed: function() {
      Quagga.stop();
    },
  };
</script>

<style scoped>
  .viewport {
    position: relative;
  }

  .viewport canvas,
  .viewport video {
    position: absolute;
    left: 0;
    top: 0;
  }
</style>
